import { takeEvery, put, call } from "redux-saga/effects"
import { API_URL } from "../../../helpers/api_helper";
//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { setupProfileSuccessful, registerUserFailed } from "./actions"

//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import * as url from "../../../helpers/url_helper"

// initialize relavant method of both Auth
// const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    const BToken = localStorage.getItem("soloAuth");
    const BearerToken = JSON.parse(BToken);
    // console.log(`Bearer Token: ${BearerToken}`);
    fetch(`${API_URL}${url.PUT_SETUP_PROFILE}`, {
      method: 'put',
      headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: user.name,
      })
    })
    .then(res => res.json())
    .then(data => {
      // yield put(setupProfileSuccessful(data))
      console.log(history);
      history.push("/dashboard")
      
    }).catch(function (error) {
      console.error(error);
    });

  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

function* accountSaga() {
  yield takeEvery(REGISTER_USER, registerUser)
}

export default accountSaga
