import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import JsonEditor from "./JsonEditor";
import {
	Container,
	FormGroup,
	Label,
	Button,
	Form,
	Input,
	Col,
	Card,
	CardBody,
	CardTitle,

} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import $ from "jquery"

class CampaignMetafields extends Component {
	constructor(props) {
		super(props)
		this.state = {
			store_domain: "",
			loading: false,
			store_status: "",
			isSaved: false,
			jsonError: "",
			metafields: {
				block: {
					show_cart: false,
					cart_bubble: '#cart-icon-bubble',
					product: {
						regular: ".price__regular .price-item.price-item--regular, .product-block--price .product__price--compare",
						sale: ".price__sale .price-item.price-item--sale, .product-block--price .on-sale, .product-block--price [data-product-price]",
						compare: ".price__sale .price-item.price-item--regular, .product-block--price .product__price-savings "
					},
					collection: {
						grid: ".card__content .card__information",
						regular: ".price__regular .price-item.price-item--regular, .grid-product__price .grid-product__price--original",
						sale: ".price__sale .price-item.price-item--sale, .grid-product__price .grid-product__price--sale",
						compare: ".price__sale .price-item.price-item--regular",
					},
					cart: {
						price: ".cart-item .cart-item__details .cart-item__discounted-prices, .cart-item .cart-item__details div.product-option, .cart__price [data-cart-item-regular-price-group] [data-cart-item-regular-price]",
						total: ".cart-item .cart-item__totals .cart-item__discounted-prices,cart-item .cart-item__totals.small-hide .price.price-end, .cart__final-price [data-cart-item-final-price-group] [data-cart-item-final-price]",
						subtotal: ".totals .totals__subtotal-value, .cart-subtotal__price[data-cart-subtotal]",
					},
					drawer: {
						price: ".cart-item .cart-item__details .cart-item__discounted-prices, .cart-item .cart-item__details div.product-option, .cart__price [data-cart-item-regular-price-group] [data-cart-item-regular-price]",
						total: ".cart-item .cart-item__totals .cart-item__discounted-prices, .cart-item .cart-item__totals .price.price--end, .cart__final-price [data-cart-item-final-price-group] [data-cart-item-final-price]",
						subtotal: ".totals .totals__subtotal-value, .cart-subtotal__price[data-cart-subtotal]",
					}
				}
			}
		}
	}
	componentDidMount() {
		const { data } = this.props.location;
		if (!data) {
			return window.location.href = '/stores';
		}
		const { store_domain, store_details, store_status } = data;
		this.setState({
			store_domain,
			store_details: { ...store_details },
			store_status
		})

		this.getMetafields(store_domain);

	}
	handleValidSubmit = (e) => {
		e.preventDefault();
		const data = this.state;
		$(".loading").text("Loading ...").css('color', 'red');

		const {
			store_domain,
			isSaved,
			metafields,
			jsonError
		} = data;
		if (jsonError) {
			$(".loading").text("JSON Error").css('color', 'red');
			return false
		}

		const BToken = localStorage.getItem("veeperAuthToken");
		const BearerToken = JSON.parse(BToken);

		fetch(`${API_URL}${url.CUSTOM_BLOCK_METAFIELDS}`, {
			method: 'put',
			headers: {
				'Authorization': `${BearerToken}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				store: store_domain,
				metafields
			})
		})
			.then(res => res.json())
			.then(data => {
				$(".loading").text("");
				this.setState({
					loading: true
				})
			})
	}
	handleJsonError = (error) => {
		this.setState({
			...this.state,
			jsonError: error
		})
	}

	handleJsonChange = (jsonData) => {
		// Handle the JSON data changes here
		try {
			const parsedJson = JSON.parse(jsonData);
			this.setState({
				...this.state,
				metafields: parsedJson,
				jsonError: ''
			})

		} catch (error) {
			this.setState({
				...this.state,
				jsonError: 'Error parsing JSON. Please check the JSON syntax and try again.'
			})
		}
	};

	fetchData = async (apiUrl, BearerToken) => {
		const response = await fetch(apiUrl, {
			method: 'get',
			headers: {
				'Authorization': `${BearerToken}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		});

		if (!response.ok) {
			throw new Error(`Network response was not ok`)
		}

		return await response.json()

	}
	getMetafields = async (store_domain) => {
		// fetch 

		const BToken = localStorage.getItem("veeperAuthToken");
		const BearerToken = JSON.parse(BToken);
		const apiUrl = `${API_URL}${url.CUSTOM_BLOCK_METAFIELDS}?store=${store_domain}`;
		const res = await this.fetchData(apiUrl, BearerToken)

		if (res) {
			if (!Object.keys(res?.data)?.length) return;

			this.setState({
				metafields: {
					block: res?.data
				}
			})
		}

	}
	render() {
		const { jsonError, store_domain, metafields, isSaved, loading, store_details, store_status } = this.state;

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>Veeper | AP - Metafields Edit</title>
					</MetaTags>
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={("Metafields Edit")}
							breadcrumbItem={("Edit Metafields")}
						/>
						<Col md={12}>
							{loading && <div class="alert alert-success" role="alert">
								Metafields has been updated successful!
							</div>}
							<Card>
								<CardBody>
									<CardTitle className="h4"></CardTitle>
									<Link to={{ pathname: "/merchant-details", data: store_details, storeStatus: store_status }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

										<Button className="btn btn-dark">
											<i className="fa fa-arrow-left"></i> Store Details
										</Button>
									</Link>
									<Form onSubmit={this.handleValidSubmit}>
										<FormGroup className="row mb-4">
											<Label htmlFor="horizontal-store_domain-Input" className="col-sm-2 col-form-label text-md-right" style={{ textAlign: "right" }} >
												Store Domain
											</Label>
											<Col sm={4}>
												<Input value={store_domain} type="text" name="store" className="form-control" id="horizontal-store_domain-Input" readOnly />
											</Col>
										</FormGroup>
										<FormGroup className="row mb-4">
											<Label htmlFor="horizontal-placement-Input" className="col-sm-2 col-form-label text-md-right" style={{ textAlign: "right" }} >
												Metafields
											</Label>
											<Col sm={9}>
												<JsonEditor initialValue={metafields} onChange={this.handleJsonChange} />

												<p style={{ color: 'red' }}>{jsonError ? jsonError : ""}</p>
											</Col>
										</FormGroup>

										<FormGroup className="row justify-content-end">
											<Col sm={10}>
												<div>
													<Button type="submit" color="primary" className="w-md">
														{/* {!isSaved && "Update"}
														{isSaved && "Save"} */}
														Save
													</Button> &nbsp; &nbsp; &nbsp; <span className="loading"></span>
												</div>
											</Col>
										</FormGroup>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

CampaignMetafields.propTypes = {
	t: PropTypes.any
}
export default CampaignMetafields
