
import React, { useEffect, useState } from "react";

export default function JsonEditor({ initialValue, onChange }) {
  const [jsonData, setjsonData] = useState({})

  // Function to handle changes in the text area
  const handleInputChange = (event) => {
    try {
      const data = event.target.value;
      setjsonData(data)
      onChange(data)
    } catch (error) {
      console.log("Error parsing JSON. Please check the JSON syntax and try again.");
    }
  };

  useEffect(() => {
    console.log(initialValue)
    setjsonData(JSON.stringify(initialValue, null, 2))
  }, [initialValue])

  return (
    <textarea
      value={jsonData}
      onChange={handleInputChange}
      rows={25}
      spellCheck={false}

      style={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap', width: "100%" }}
    />
  );
}