

import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { Component } from "react"
import {
  Container,
  Button,
  Input,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardColumns,
} from "reactstrap"
import { Link } from "react-router-dom"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import { ToastContainer, toast } from "react-toastify";

import Breadcrumbs from "../../components/Common/Breadcrumb"

class PlanInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      store_domain: "",
      loading: false,
      store_details: {},
      store_status: "",
      message: "",
      alert: "success",
      coupon: '',

      Coupons: 0,
      totalCoupons: 0,
      newTrialDays: 1
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }


  notify_info = (message) => toast.info(`${message}`);
  notify_error = (message) => toast.error(`${message}`);
  notify_success = (message) => toast.success(`${message}`);
  getBearToken = () => {
    const BToken = localStorage.getItem("veeperAuthToken");
    return JSON.parse(BToken);
  }

  componentDidMount() {
    const { data } = this.props.location;
    if (!data) {
      return window.location.href = '/stores';
    }
    const { store_domain, store_details, store_status } = data;
    if (store_details?.current_period_start) {
      const current_period_start = new Date(store_details?.current_period_start).getTime();
      const now = Date.now();
      // day difference 
      let trialDays = Math.floor((current_period_start - now) / (1000 * 60 * 60 * 24));
      if (trialDays <= 0) {
        trialDays = 0;
      }

      this.setState({
        store_domain,
        store_details: { ...store_details, trialDays },
        store_status
      });
    } else {
      this.setState({
        store_domain,
        store_details,
        store_status
      });
    }

    setTimeout(() => this.setState({ subscribemodal: false }), 2000);
  }
  updateItem = (e) => {
    // e.preventDefault();
    this.setState({
      store_domain: e.store_domain,
      type: e.type,
    })
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  updatePlanInfo = (param) => {
    const {
      additionalOrdersPrice,
      newTrialDays,
      store_domain,
      store_details
    } = this.state;
    const BearerToken = this.getBearToken();
    if (param === "trialDays" && newTrialDays <= 0) {
      return;
    }
    if (param === 'trialDays') {
      fetch(`${API_URL}${url.EXTEND_TRIAL_DAYS}`, {
        method: 'put',
        headers: {
          'Authorization': `${BearerToken}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          shop: store_domain,
          trial_days: newTrialDays,
          subscription_id: store_details.subscription_id,
        })
      })
        .then(res => res.json())
        .then(data => {
          console.log(data);
          if (data?.error) {
            this.setState({ alert: "danger", message: data.error })
          } else {
            this.setState(prevState => ({
              store_details: {
                ...prevState.store_details,
                trialDays: parseInt(newTrialDays) + parseInt(prevState.store_details.trialDays),
              }
            }))
            this.notify_success("Plan info updated successfully");
          }
        })
        .catch(err => {
          console.log(err)
        });
      return;
    }

    fetch(`${API_URL}${url.EDIT_USAGE_CHARGES}`, {
      method: 'put',
      headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        shop: store_domain,
        usage_charges: additionalOrdersPrice,
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data?.error) {
          this.setState({ alert: "danger", message: data.error })
        } else {
          this.setState(prevState => ({
            store_details: {
              ...prevState.store_details,
              plan_info: {
                ...prevState.store_details?.plan_info,
                additionalOrdersPrice,
              }
            }
          }));
          this.notify_success("Plan info updated successfully");
        }
      })
      .catch(err => {
        console.log(err)
      })
  }


  render() {
    const { alert, message, loading, store_details, store_status, additionalOrdersPrice = store_details?.plan_info?.additionalOrdersPrice || '', trialDays = store_details?.trialDays || 0, newTrialDays = 1 } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Veeper | Subscription Plan Info </title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={("Subscription Plan Info")}
              breadcrumbItem={("Subscription Information")}
            />
            <ToastContainer />
            <Col md={12}>
              {loading && <div class={`alert alert-${alert}`} role="alert">
                {message}
              </div>}
              <Link to={{ pathname: "/merchant-details", data: store_details, storeStatus: store_status }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button className="btn btn-dark">
                  <i className="fa fa-arrow-left"></i> Store Details
                </Button>
              </Link>
            </Col>

            <Col md={8}>
              <Card>
                <CardBody>
                  <CardColumns>
                    <h5 className="mb-3">{store_details.plan || ''}</h5>
                    <h5 className="mb-3" style={{ color: store_details.plan_status === 'ACTIVE' ? 'green' : 'red', fontWeight: 'bold' }}>{store_details.plan_status || ''}</h5>
                    <h5 className="mb-3">Price:  {store_details?.plan_info?.price || ''}</h5>
                  </CardColumns>

                  <CardColumns>
                    <h5 className="mb-3">{store_details?.plan_info?.interval || ''}</h5>
                    <h5 className="mb-3">Orders:  {store_details?.plan_info?.orders || ''}</h5>
                    <h5 className="mb-3">Additional Orders: {store_details?.plan_info?.additionalOrders || ''}</h5>
                  </CardColumns>

                  <CardColumns>
                    <h5 className="mb-3">Additional Orders Price: {store_details?.plan_info?.additionalOrdersPrice || ''}</h5>
                    <h5 className="mb-3">Trial Days: {store_details?.trialDays || ''}</h5>
                  </CardColumns>
                  <hr />
                  {/* Update Plan Info center */}
                  <CardTitle className="text-center mb-3" >Update Plan Info</CardTitle>
                  <CardColumns>
                    <h5 className="mb-3">Additional Orders Price: <Input name="additionalOrdersPrice" type="text" value={additionalOrdersPrice} onChange={this.handleInputChange} /></h5>
                    <Button className="btn btn-primary" onClick={() => this.updatePlanInfo("additionalOrdersPrice")}>Update</Button>
                  </CardColumns>
                  <hr />
                  <CardColumns>
                    <h5 className="mb-3">Trial Days: <Input name="newTrialDays" type="text" value={newTrialDays} onChange={this.handleInputChange} /></h5>
                    <Button className="btn btn-primary" onClick={() => this.updatePlanInfo("trialDays")} disabled={trialDays <= 0}>Update</Button>
                  </CardColumns>

                </CardBody>
              </Card>
            </Col>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

PlanInfo.propTypes = {
  t: PropTypes.any
}
export default PlanInfo
