import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import $ from 'jquery'
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import * as url from "../../helpers/url_helper"
import {API_URL} from "../../helpers/api_helper";

import CustomersList from "./CustomersList";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class Shoppers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      TotalCustomers: 0,
      CustomersDetails: 0,
      loading: false
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }

  componentWillMount() {
    // this.setState({loading: true});
    const BToken = localStorage.getItem("veeperAuthToken");
    const BearerToken = JSON.parse(BToken);
    // console.log(BearerToken)
    $("#loadingIcon").text("Loading ...").css('color', 'red');
    fetch(`${API_URL}${url.GET_VEEPER_CUSTOMERS}`, {
      method: 'get',
      headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(results => {
      const {
        status,
		    data: shoppers,
      } = results ; 
      let customer_list = shoppers.map(single_customer => ({
        customer: single_customer.name,
        email: single_customer.email,
        sales: single_customer.orders_count,
        subtotal: single_customer.overall_total_spent,
        aov: (single_customer.orders_count)? (single_customer.overall_total_spent/single_customer.orders_count).toFixed(2) : 0,
        date: '',
      }));
      // console.log(customer_list)
      const data = {
        columns: [
          {
            label: "Name",
            field: "customer",
            sort: "asc",
            width: 150,
          },
          {
            label: "Email",
            field: "email",
            sort: "asc",
            width: 150,
          },
          
          {
            label: "No. of Sales",
            field: "sales",
            sort: "asc",
            width: 270,
          },
          {
            label: "SubTotal",
            field: "subtotal",
            sort: "asc",
            width: 200,
          },
          {
            label: "AOV",
            field: "aov",
            sort: "asc",
            width: 100,
          },
          {
            label: "Last Purchases",
            field: "date",
            sort: "asc",
            width: 150,
          }
        ],
        rows: customer_list,
      }
  
      this.setState({ 
        TotalCustomers: shoppers.length,
        CustomersDetails: data,
        loading: true
      })
  
    }).catch(function (error) {
      console.log(error);
    });
  }
  
  componentDidMount() {
    setTimeout(() => this.setState({ subscribemodal: false }), 2000);   

  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  render() {
    const { 
      CustomersDetails,
      TotalCustomers,
      loading 
    } = this.state;
    
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Veeper | AP - Customers</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Shoppers")}
              breadcrumbItem={this.props.t("Shoppers")}              
            />
            <div className="">
              <p style={{"paddingLeft": "34px"}}>{TotalCustomers} Veeper customers</p>
            </div>
            <Row>
              <Col xl={12}> 
                <CustomersList customers={CustomersDetails} />
              </Col>
            </Row>
          </Container>
        </div>
        
      </React.Fragment>
    )

  }
}

Shoppers.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Shoppers)
