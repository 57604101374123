import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import $ from 'jquery'
import {
    Container,
    Row,
} from "reactstrap"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import { formatToShortHand } from "../../helpers/common_helper"

import BasicCountInfo from "./BasicCountInfo"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            revenue: 0,
            totalStores: 0,
            MRR: 0,
            ARR: 0,
            legacyPlan: 0,
            starterPlan: 0,
            growthPlan: 0,
            advancedPlan: 0,
            premiumPlan: 0,
            enterprisePlan: 0,
            legacyPlanPrice: 0,
            growthPlanPrice: 0,
            starterPlanPrice: 0,
            premiumPlanPrice: 0,
            advancedPlanPrice: 0,
            enterprisePlanPrice: 0,
            totalHeadlessStores: 0,
            loading: false
        }

        this.togglemodal.bind(this)
        this.togglesubscribemodal.bind(this)
    }

    componentWillMount() {
        // this.setState({loading: true});
        const BToken = localStorage.getItem("veeperAuthToken");
        const BearerToken = JSON.parse(BToken);
        // console.log(BearerToken)
        $("#loadingIcon").text("Loading ...").css('color', 'red');
        fetch(`${API_URL}${url.GET_DASHBOARD}`, {
            method: 'get',
            headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    ...data,
                    loading: true
                })

            }).catch(function (error) {
                console.log(error);
            });
    }

    componentDidMount() {
        setTimeout(() => this.setState({ subscribemodal: false }), 2000);

    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }))
    }

    togglesubscribemodal = () => {
        this.setState(prevState => ({
            subscribemodal: !prevState.subscribemodal,
        }))
    }

    render() {
        const {
            revenue = 0,
            totalStores = 0,
            MRR = 0,
            ARR = 0,
            legacyPlan = 0,
            starterPlan = 0,
            growthPlan = 0,
            advancedPlan = 0,
            premiumPlan = 0,
            enterprisePlan = 0,
            legacyPlanPrice = 0,
            growthPlanPrice = 0,
            starterPlanPrice = 0,
            premiumPlanPrice = 0,
            advancedPlanPrice = 0,
            enterprisePlanPrice = 0,
            totalHeadlessStores = 0,
        } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Veeper | AP - Home</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={this.props.t("Home")}
                            breadcrumbItem={this.props.t("How We're doing")}
                        />
                        <Row>
                            <BasicCountInfo store_domain="" colSize="3" data={'$' + formatToShortHand(MRR)} title="MRR" />
                            <BasicCountInfo store_domain="" colSize="3" data={'$' + formatToShortHand(ARR)} title="ARR" />
                            <BasicCountInfo store_domain="" colSize="3" data={totalStores} linkUrl="stores" title="Clients" />
                            <BasicCountInfo store_domain="" colSize="3" data={'$' + formatToShortHand(revenue)} title="Revenue" />
                            <BasicCountInfo store_domain="" colSize="3" data={totalHeadlessStores} title="Headless Stores" />
                            <BasicCountInfo store_domain="" colSize="3" data={legacyPlan} price={formatToShortHand(legacyPlanPrice)} secondParams='yes' title="Legacy Plan" />
                            <BasicCountInfo store_domain="" colSize="3" data={starterPlan} price={formatToShortHand(starterPlanPrice)} secondParams='yes' title="Starter Plan" />
                            <BasicCountInfo store_domain="" colSize="3" data={growthPlan} price={formatToShortHand(growthPlanPrice)} secondParams='yes' title="Growth Plan" />
                            <BasicCountInfo store_domain="" colSize="3" data={advancedPlan} price={formatToShortHand(advancedPlanPrice)} secondParams='yes' title="Advanced Plan" />
                            <BasicCountInfo store_domain="" colSize="3" data={premiumPlan} price={formatToShortHand(premiumPlanPrice)} secondParams='yes' title="Premium Plan" />
                            <BasicCountInfo store_domain="" colSize="3" data={enterprisePlan} price={formatToShortHand(enterprisePlanPrice)} secondParams='yes' title="Enterprise Plan" />
                        </Row>

                    </Container>
                </div>

            </React.Fragment>
        )

    }
}

Dashboard.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(Dashboard)
