

import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { Component } from "react"
import {
  Container,
  FormGroup,
  Label,
  Button,
  Form,
  Input,
  Col,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import { ToastContainer, toast } from "react-toastify";

import CouponsTable from "../BlockedCoupon/coupons";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import $ from "jquery"

class BlockedCouponView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      store_domain: "",
      loading: false,
      store_details: {},
      store_status: "",
      message: "",
      alert: "success",
      coupon: '',

      Coupons: 0,
      totalCoupons: 0,
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }


  notify_info = (message) => toast.info(`${message}`);
  notify_error = (message) => toast.error(`${message}`);
  notify_success = (message) => toast.success(`${message}`);
  getBearToken = () => {
    const BToken = localStorage.getItem("veeperAuthToken");
    return JSON.parse(BToken);
  }

  delete_coupon = (coupon) => {
    let answer = window.confirm("Are you sure you want to delete this coupon?");
    if (!answer) {
      return;
    }
    const BearerToken = this.getBearToken();
    fetch(`${API_URL}${url.BLOCKED_COUPON}`, {
      method: 'delete',
      headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        coupon,
        store: this.state.store_domain
      })
    })
      .then(res => res.json())
      .then(results => {
        this.notify_success("Blocked coupon has been deleted successfully");
        this.blocked_coupons()
      }).catch(err => {
        this.notify_error("Blocked coupon could not delete!");
      });
  }
  blocked_coupons = () => {
    const { data } = this.props.location;
    const BearerToken = this.getBearToken();
    $("#loadingIcon").text("Loading ...").css('color', 'red');
    fetch(`${API_URL}${url.BLOCKED_COUPON}?store=${data.store_domain}`, {
      method: 'get',
      headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(results => {
        const { data: coupons } = results;
        let customer_list = coupons.map(ele => ({
          name: ele,
          action: (<span>
            <Button
              color="danger"
              onClick={() => this.delete_coupon(ele)}
              className="btn btn-danger waves-effect waves-light"
              data-toggle="modal"
              data-target="#myModal"
            > Delete </Button>
          </span>)

        }));
        const data = {
          columns: [
            {
              label: "Name",
              field: "name",
              sort: "asc",
              width: "600",
            },
            {
              label: "Action",
              field: "action",
              sort: "disabled",
              width: "100",
            }
          ],
          rows: customer_list,
        }

        this.setState({
          totalCoupons: coupons.length,
          Coupons: data
        })

      }).catch(function (err) {
        console.log(err);
      });
  }
  componentWillMount() {
    this.blocked_coupons()
  }
  componentDidMount() {
    const { data } = this.props.location;
    if (!data) {
      return window.location.href = '/stores';
    }
    const { store_domain, store_details, store_status } = data;
    this.setState({
      store_domain,
      store_details: { ...store_details },
      store_status
    })

    setTimeout(() => this.setState({ subscribemodal: false }), 2000);
  }
  updateItem = (e) => {
    // e.preventDefault();
    this.setState({
      store_domain: e.store_domain,
      type: e.type,
    })
  }
  handleValidSubmit = (e) => {
    e.preventDefault();
    const { store_domain, coupon } = this.state;
    const BearerToken = this.getBearToken();
    let body = {
      store: store_domain,
      coupons: [coupon]
    }

    fetch(`${API_URL}${url.BLOCKED_COUPON}`, {
      method: 'post',
      headers: {
        'Authorization': `${BearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(data => {
        if (data?.error) {
          this.setState({
            alert: "danger",
            message: data.error
          })
        } else {
          this.setState({ coupon: '' })
          this.blocked_coupons()
          this.notify_success("Blocked coupon added has been added successfully");
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }


  render() {
    const { alert, message, loading, store_details, store_status, Coupons, coupon } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Veeper | AP -Blocked Coupon </title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={("Blocked Coupon List")}
              breadcrumbItem={("Information")}
            />
            <ToastContainer />
            <Col md={12}>
              {loading && <div class={`alert alert-${alert}`} role="alert">
                {message}
              </div>}
              <Card>
                <CardBody>
                  <CardTitle className="h4"></CardTitle>
                  <Link to={{ pathname: "/merchant-details", data: store_details, storeStatus: store_status }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button className="btn btn-dark">
                      <i className="fa fa-arrow-left"></i> Store Details
                    </Button>
                  </Link>
                  <Form onSubmit={this.handleValidSubmit}>
                    <FormGroup className="row mb-4">
                      <Label
                        htmlFor="horizontal-store_domain-Input"
                        className="col-sm-1 col-form-label text-md-right"
                        style={{ textAlign: "center" }}
                      >
                        Coupon
                      </Label>
                      <Col sm={3}>
                        <Input
                          value={coupon}
                          type="text"
                          name="coupon"
                          className="form-control"
                          placeholder='Please write blocked coupon'
                          id="horizontal-store_domain-Input"
                          onChange={this.handleInputChange}
                          required={true}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row justify-content-end">
                      <Col sm={11}>
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            Save
                          </Button> &nbsp; &nbsp; &nbsp; <span className="loading"></span>
                        </div>
                      </Col>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col md={12}>
              <CouponsTable data={Coupons} />
            </Col>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

BlockedCouponView.propTypes = {
  t: PropTypes.any
}
export default BlockedCouponView
