import PropTypes from 'prop-types'
import React, { Component } from "react"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import config
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";


// actions
import {
    apiError,
    loginUser,
    socialLogin,
    appleSocialLogin,
    googleSocialLogin,
    facebookSocialLogin,
} from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {}

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        fetch(`${API_URL}${url.POST_FAKE_JWT_LOGIN}`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                "access-control-allow-origin": "*",
                'Access-Control-Allow-Credentials': 'true',
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(values)
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else if (res.status === 401) {
                    throw new Error("Invalid email or password")
                } else {
                    throw new Error(res.statusText)
                }
            })
            .then(resData => {
                console.log(resData)
                const { data: { accessToken } } = resData;
                const token_authorization = `Bearer ${accessToken}`;
                localStorage.setItem("veeperAuthToken", JSON.stringify(token_authorization));
                this.props.loginUser(values, this.props.history);
            }).catch(function (error) {
                alert(error)
            });
    }

    componentDidMount() {
        this.props.apiError("")
    }

    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-primary bg-soft">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">Welcome to Veeper !</h5>
                                                    <p>Sign in to Veeper Admin Panel.</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profile} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div className="p-0">
                                            <AvForm
                                                className="form-horizontal"
                                                onValidSubmit={this.handleValidSubmit}
                                            >
                                                {this.state.error && this.props.error ? (
                                                    <Alert color="danger">{this.props.error}</Alert>
                                                ) : null}

                                                <div className="mt-3">
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="email"
                                                            label="Email"
                                                            value=""
                                                            className="form-control"
                                                            placeholder="Enter email"
                                                            type="email"
                                                            required
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <AvField
                                                            name="password"
                                                            label="Password"
                                                            value=""
                                                            type="password"
                                                            required
                                                            placeholder="Enter Password"
                                                        />
                                                    </div>

                                                    <div className="form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customControlInline"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customControlInline"
                                                        >
                                                            Remember me
                                                        </label>
                                                    </div>

                                                    <div className="mt-3 d-grid">
                                                        <button
                                                            className="btn btn-primary btn-block waves-effect waves-light"
                                                            type="submit"
                                                        >
                                                            Log In
                                                        </button>
                                                    </div>

                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p>
                                        © {new Date().getFullYear()} Real Life Apps
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </React.Fragment>
        )
    }
}

Login.propTypes = {
    apiError: PropTypes.any,
    error: PropTypes.any,
    history: PropTypes.object,
    loginUser: PropTypes.func,
    socialLogin: PropTypes.func,
    facebookSocialLogin: PropTypes.func,
    googleSocialLogin: PropTypes.func,
    appleSocialLogin: PropTypes.func
}

const mapStateToProps = state => {
    const { error } = state.Login
    return { error }
}

export default withRouter(
    connect(mapStateToProps, { loginUser, apiError, socialLogin, facebookSocialLogin, googleSocialLogin, appleSocialLogin })(Login)
)
