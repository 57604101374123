import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import $ from 'jquery'
import {
    Container,
    Row,
    Col,
    Button,
} from "reactstrap"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import { Link } from "react-router-dom"
import MerchantsList from "./MerchantsList"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { formatToShortHand } from "../../helpers/common_helper"

//i18n
import { withTranslation } from "react-i18next"

class Stores extends Component {
    constructor(props) {
        super(props)
        this.state = {
            TotalMerchants: 0,
            MerchantsDetails: 0,
            loading: false
        }

        this.togglemodal.bind(this)
        this.togglesubscribemodal.bind(this)
    }

    formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    componentWillMount() {
        // this.setState({loading: true});
        const BToken = localStorage.getItem("veeperAuthToken");
        const BearerToken = JSON.parse(BToken);
        // console.log(BearerToken)
        $("#loadingIcon").text("Loading ...").css('color', 'red');
        fetch(`${API_URL}${url.GET_DASHBOARD_MERCHANTS}`, {
            method: 'get',
            headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(results => {
                let merchants_list = results?.data?.map(merchant => ({

                    store_name: merchant.store_name,
                    store_domain: <Link to={{ pathname: `https://${merchant.store_domain}` }} target="_blank" rel="noreferrer">{merchant.store_domain}</Link>,
                    link: <Link to={{ pathname: "/merchant-details", data: merchant, storeStatus: 'active' }} ><i className="fas fa-eye"></i> Details</Link>,
                    plan: merchant.plan ? `${merchant.plan} (${merchant.plan_status})` : "Not Updated",
                    recovered_revenue: '$' + formatToShortHand(parseInt(merchant.recovered_revenue)),
                    discount: '$' + parseFloat(merchant.total_discount).toFixed(2),
                    created: this.formatDate(merchant.created_at)
                }));

                const table_records = {
                    columns: [
                        {
                            label: "Store Name",
                            field: "store_name",
                            sort: "asc",
                            width: 150,
                        },
                        {
                            label: "Store Domain",
                            field: "store_domain",
                            sort: "asc",
                            width: 150,
                        },
                        {
                            label: "Details",
                            field: "link",
                            width: 150,
                        },
                        {
                            label: "Plan",
                            field: "plan",
                            sort: "asc",
                            width: 270,
                        },
                        {
                            label: "Recovered Revenue",
                            field: "recovered_revenue",
                            sort: "asc",
                            width: 200,
                        },
                        {
                            label: "Discount",
                            field: "discount",
                            sort: "asc",
                            width: 100,
                        },
                        {
                            label: "Installed",
                            field: "created",
                            sort: "asc",
                            width: 100,
                        }
                    ],
                    rows: merchants_list,
                }
                this.setState({
                    TotalMerchants: results?.data?.length || 0,
                    MerchantsDetails: table_records,
                    loading: true
                })

            }).catch(function (error) {
                console.log(error);
            });
    }

    componentDidMount() {
        setTimeout(() => this.setState({ subscribemodal: false }), 2000);
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }))
    }

    togglesubscribemodal = () => {
        this.setState(prevState => ({
            subscribemodal: !prevState.subscribemodal,
        }))
    }

    updateScript = () => {
        $(".loading").text(`Updating ...  `).css('color', 'red')
        const BToken = localStorage.getItem("veeperAuthToken");
        const BearerToken = JSON.parse(BToken);
        fetch(`${API_URL}${url.GET_UPDATE_SCRIPT}`, {
            method: 'get',
            headers: {
                'Authorization': `${BearerToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(results => {
                // const {
                //     status,
                //     message,
                // } = results;
                $(".loading").text(``)
            }).catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const {
            MerchantsDetails,
            loading
        } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Veeper | AP - Stores</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={this.props.t("Stores")}
                            breadcrumbItem={this.props.t("Stores")}
                        />
                        <Row>
                            <Col xl={12}>
                            </Col>
                        </Row>

                        <Row>
                            <div className="" style={{ "textAlign": "right", "paddingBottom": "10px" }}>
                                <span className="loading"></span>
                                <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={this.updateScript} >Add Script</Button>
                            </div>
                            <Col xl={12}>
                                <MerchantsList data={MerchantsDetails} default_entries={200} />
                            </Col>
                        </Row>
                    </Container>
                </div>

            </React.Fragment>
        )

    }
}

Stores.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(Stores)
