import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, } from "reactstrap"

export default class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
    this.toggledropdown = this.toggledropdown.bind(this)
  }

  toggledropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }
  render() {
    const { title, colSize, store_details, store_domain, store_status } = this.props;

    return (
      <React.Fragment>
        <Col xl={colSize}>
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap d-between-flex" >
                <h3 className="card-title mb-3 me-2">{title}</h3>
                <Link to={{
                  pathname: "/common-setting-edit",
                  data: { store_domain, store_details, store_status },
                }} >
                  <i className="fa fa-edit"></i> Edit
                </Link>
              </div>
              <div>
                <h6 className="mb-2">
                  Campaigns &thinsp; &thinsp;<span style={{ color: "Highlight" }}>{`${store_details?.is_campaign_links}` || false}</span>
                </h6>
                <h6 className="mb-2">
                  Coupon Blocker &thinsp; &thinsp;<span style={{ color: "Highlight" }}>{`${store_details?.is_coupon_blocker}` || false}</span>
                </h6>
                <h6 className="mb-2">
                  Smart Discount &thinsp; &thinsp;<span style={{ color: "Highlight" }}>{`${store_details?.is_smart_discount}` || false}</span>
                </h6>
              </div>

            </CardBody>
          </Card>
        </Col>
      </React.Fragment >
    )
  }
}
