import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
} from "reactstrap"

export default class DiscountType extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
    this.toggledropdown = this.toggledropdown.bind(this)
  }

  toggledropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }
  render() {
    const { type, range, title, colSize, store_details, store_domain, store_status, is_campaign, is_selector } = this.props;

    return (
      <React.Fragment>
        <Col xl={colSize}>
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap d-between-flex" >
                <h3 className="card-title mb-3 me-2">
                  {title}
                </h3>
                {!is_campaign && is_selector && <Link to={{
                  pathname: "/price-selector-edit",
                  data: { store_domain, store_details, store_status },
                }} >
                  <i className="fa fa-edit"></i> Edit
                </Link>
                }
                {is_campaign && <Link to={{
                  pathname: "/campaign-metafields-edit",
                  data: { store_domain, store_details, store_status },
                }} >
                  <i className="fa fa-edit"></i> Edit
                </Link>
                }
                {!is_campaign && !is_selector &&
                  <Link to={{
                    pathname: "/discount-type-edit",
                    data: { store_domain, max_discount: range, type, store_details, store_status },
                  }} >
                    <i className="fa fa-edit"></i> Edit
                  </Link>
                }
              </div>
              <div className="d-flex flex-wrap">
                <div>
                  <h6 className="mb-3 text-primary" ><i>{type} </i></h6>
                  {type === 'Manual' && <h5 className="mb-0 mb">{range}px</h5>}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}
