import React from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody } from "reactstrap"

import "../Shoppers/datatables.scss"


const MerchantsList = ({ data, option = [10, 20, 30, 40, 60, 80, 100, 200], default_entries = 10 }) => {

    return (
        <React.Fragment>
            {/* <div><h4>{{ title }}</h4></div> */}
            <Row>
                <Col className="col-12">
                    <Card>
                        <CardBody>
                            <MDBDataTable entriesOptions={option} responsive bordered data={data} entries={default_entries} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default MerchantsList
