import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import JsonEditor from "./JsonEditor";
import {
	Container,
	FormGroup,
	Label,
	Button,
	Form,
	Input,
	Col,
	Card,
	CardBody,
	CardTitle,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import $ from "jquery"

class PlacementEdit extends Component {
	constructor(props) {
		super(props)
		this.state = {
			shop: "",
			value: 60,
			loading: false,
			store_details: {},
			store_status: "",
			field: "",

			jsonError: "",

		}
	}
	componentDidMount() {
		const { data } = this.props.location;
		if (!data) {
			return window.location.href = '/stores';
		}
		const { store_domain, value, store_details, store_status, field } = data;
		this.setState({
			shop: store_domain,
			value: field === 'cart_location' ? value ? JSON.parse(value) : {} : value,
			store_details: { ...store_details },
			store_status,
			field
		})
	}
	handleValidSubmit = (e) => {
		e.preventDefault();
		const { shop, value, field, store_details } = this.state;

		const BToken = localStorage.getItem("veeperAuthToken");
		const BearerToken = JSON.parse(BToken);
		$(".loading").text("Loading ...").css('color', 'red');
		let body = {
			shop,
		}
		if (field !== 'placement') {
			body = {
				...body,
				properties: {
					store_id: store_details.id,
					[field]: value,
				}

			}
		} else {
			body = { ...body, [field]: parseInt(value) }
		}
		const main_url = (field === 'placement' || field === 'total_fees') ? `${API_URL}${url.PUT_UPDATE_MERCHANTS}` : `${API_URL}${url.PUT_UPDATE_STORE_SETTINGS}`

		// return;

		fetch(`${main_url}`, {
			method: 'put',
			headers: {
				'Authorization': `${BearerToken}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		})
			.then(res => res.json())
			.then(data => {
				$(".loading").text("");
				this.setState({
					loading: true
				})
			})
	}
	handleJsonError = (error) => {
		this.setState({
			...this.state,
			jsonError: error
		})
	}
	handleInputChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	handleJsonChange = (jsonData) => {
		// Handle the JSON data changes here
		try {
			const parsedJson = JSON.parse(jsonData);
			this.setState({
				...this.state,
				value: parsedJson,
				jsonError: ''
			})

		} catch (error) {
			this.setState({
				...this.state,
				jsonError: 'Error parsing JSON. Please check the JSON syntax and try again.'
			})
		}
	};


	render() {
		const { shop, value, field, loading, store_details, store_status, jsonError } = this.state;
		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>Veeper | AP - Placement Edit</title>
					</MetaTags>
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={(`${field}`)}
							breadcrumbItem={("Edit")}
						/>
						<Col md={12}>
							{loading && <div class="alert alert-success" role="alert">
								{`${field} has been updated successful!`}
							</div>}
							<Card>
								<CardBody>
									<CardTitle className="h4"></CardTitle>
									<Link to={{ pathname: "/merchant-details", data: store_details, storeStatus: store_status }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

										<Button className="btn btn-dark">
											<i className="fa fa-arrow-left"></i> Store Details
										</Button>
									</Link>

									<Form onSubmit={this.handleValidSubmit}>
										<FormGroup className="row mb-4">
											<Label
												htmlFor="horizontal-shop-Input"
												className="col-sm-1 col-form-label text-md-right"
												style={{ textAlign: "center" }}
											>
												Store Domain
											</Label>
											<Col sm={4}>
												<Input
													value={shop}
													type="text"
													name="shop"
													className="form-control"
													id="horizontal-shop-Input"
													onChange={this.handleInputChange}
													readOnly
												/>
											</Col>
										</FormGroup>
										<FormGroup className="row mb-4">
											<Label
												htmlFor="horizontal-placement-Input"
												className="col-sm-1 col-form-label text-md-right"
												style={{ textAlign: "center" }}
											>
												{field === "total_fees" ? "Total Fees" : null}
												{field === "placement" ? "Placement" : null}
												{field === "public_access_token" ? "Access Token" : null}
												{field === "cart_location" ? "Cart Location" : null}
											</Label>
											{field !== "cart_location" && <Col sm={4}>
												<Input
													value={value}
													type="string"
													name='value'
													className="form-control"
													id="horizontal-placement-Input"
													onChange={this.handleInputChange}
													required
												/>
											</Col>
											}
											{field === 'cart_location' && <Col sm={4}>
												<JsonEditor initialValue={value} onChange={this.handleJsonChange} />

												<p style={{ color: 'red' }}>{jsonError ? jsonError : ""}</p>
											</Col>
											}
										</FormGroup>

										<FormGroup className="row justify-content-end">
											<Col sm={11}>
												<div>
													<Button
														type="submit"
														color="primary"
														className="w-md"
													>
														Update
													</Button> &nbsp; &nbsp; &nbsp; <span className="loading"></span>
												</div>
											</Col>
										</FormGroup>
									</Form>
								</CardBody>
							</Card>
						</Col>

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

PlacementEdit.propTypes = {
	t: PropTypes.any
}
export default PlacementEdit
