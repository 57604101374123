import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN, FACEBOOK_LOGIN, GOOGLE_LOGIN, APPLE_LOGIN } from "./actionTypes"
import { loginSuccess, logoutUserSuccess, apiError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
  postFacebookLogin,
  postGoogleLogin,
  postAppleLogin
} from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      // const response = yield call(postJwtLogin, {
      //   email: user.email,
      //   password: user.password,
      // })
      // if(user.error) {
      //   console.log(user.error)
      //   yield put(apiError(user.error))
      //   return;
      // }
      
      const response = {
        email: user.email
      }

      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      // const response = yield call(postSocialLogin, data);
      // console.log(`response: ${response}`);
      localStorage.setItem("authUser", JSON.stringify(data))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* facebookSocialLogin({ payload: { data, history, type } }) {
  try {
    // console.log({data, history, type});
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      console.log(`ELse Section`)
      // const response = yield call(postFacebookLogin, data);
      // console.log(`Response: ${JSON.stringify(data)}`);

      localStorage.setItem("authUser", JSON.stringify(data))
      yield put(loginSuccess(data))
    }
    // console.log({is_registered: data.is_registered})
    if(data.is_registered) {
      history.push("/dashboard")
    } else {
      history.push("/profile")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* googleSocialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      // const response = yield call(postGoogleLogin, data)
      localStorage.setItem("authUser", JSON.stringify(data))
      yield put(loginSuccess(data))
    }
    // console.log({is_registered:data.is_registered})
    if(data.is_registered) {
      history.push("/dashboard")
    } else {
      history.push("/profile")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* appleSocialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      // const response = yield call(postAppleLogin, data)
      localStorage.setItem("authUser", JSON.stringify(data))
      yield put(loginSuccess(data))
    }
    if(data.is_registered) {
      history.push("/dashboard")
    } else {
      history.push("/profile")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeLatest(FACEBOOK_LOGIN, facebookSocialLogin)
  yield takeLatest(GOOGLE_LOGIN, googleSocialLogin)
  yield takeLatest(APPLE_LOGIN, appleSocialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
