import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, } from "reactstrap"

export default class BlockedCoupon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
    this.toggledropdown = this.toggledropdown.bind(this)
  }

  toggledropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }
  render() {
    const { title, colSize, store_details, store_domain, store_status, is_plan } = this.props;

    return (
      <React.Fragment>
        <Col xl={colSize}>
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap d-between-flex" >
                <h3 className="card-title mb-3 me-2">{title}</h3>
                {!is_plan && <Link to={{
                  pathname: "/blocked-coupon",
                  data: { store_domain, store_details, store_status },
                }} >
                  <i className="fa fa-eye"></i> View
                </Link>}

                {is_plan && <Link to={{
                  pathname: "/plan-info",
                  data: { store_domain, store_details, store_status },
                }} >
                  <i className="fa fa-eye"></i> View
                </Link>}
              </div>
              {is_plan && <span>
                <h6 className="mb-3">{store_details.plan || ''}</h6>
                <h7 className="mb-3" style={{ color: store_details.plan_status === 'ACTIVE' ? 'green' : 'red', fontWeight: 'bold' }}>{store_details.plan_status || ''}</h7>
              </span>
              }
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}
