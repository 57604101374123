import {
  REGISTER_USER,
  REGISTER_USER_FAILED,
  SETUP_PROFILE_SUCCESSFUL,
} from "./actionTypes"

export const registerUser = (user, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, history },
  }
}

export const setupProfileSuccessful = user => {
  return {
    type: SETUP_PROFILE_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}
