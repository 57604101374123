import React from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody } from "reactstrap"

import "../Shoppers/datatables.scss"

const PlatformList = ({ customers }) => {
  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>

              <MDBDataTable responsive bordered data={customers} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default PlatformList
